<template>
  <div class="login-main">
    <div class="login-box">
      <div class="login-title">
        <h3>{{ siteName }}</h3>
      </div>
      <div class="login-form">
        <el-form :model="form"  class="linear-form">
          <el-form-item>
            <el-input v-model.trim="form.username" placeholder="用户名" @keydown.enter="loginAct"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="form.password" placeholder="密码" type="password" show-password @keydown.enter="loginAct"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="large" class="linear-color" @click="loginAct">登录</el-button>
          </el-form-item>
        </el-form>
      </div>


    </div>
  </div>
</template>

<script setup>
import {ref, reactive} from 'vue'
import {ElMessage} from 'element-plus'
import {login} from "@/utils/apis";
import {useRouter} from 'vue-router'
const router = useRouter()
const siteName = ref('宝龙年度树向生长')
const form = reactive({
  username: '',
  password: ''
})
const loginAct = async () => {
  if (!form.username.trim().length) {
      return ElMessage.warning('用户名不能为空')
  }
  if (!form.password.trim().length) {
      return ElMessage.warning('密码不能为空')
  }
  try {
    const {data, msg} = await login(form)

    localStorage.setItem('nickname', data.nickname);
    localStorage.setItem('token', data.token)
    localStorage.setItem('avatar', data.avatar)
    router.push('/admin')
  } catch (e) {
    ElMessage.warning(e.message)

  }



}

</script>

<style scoped lang="scss">
.login-main {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
  &,& * {
    box-sizing: border-box;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .login-box {
    background-color: #fff;
    width: 358px;
    border-radius: 15px;
    padding: 0 50px;
    .login-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .linear-color {
        width: 100%;
        background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
        color: #FFF;
    }
  }
}
</style>